
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Category } from '@/interfaces/models/Category';
import { Banner } from '@/interfaces/models/Banner';
import { Article } from '@/interfaces/models/Article';
import { Venue } from '@/interfaces/models/Venue';
import _ from 'lodash';

@Component({
  components: { VTable },
})
export default class BannerTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Banner[];
  @Prop({ type: Array, required: true }) public articles!: Array<Partial<Article>>;
  @Prop({ type: Array, required: true }) public categories!: Category[];

  public headers: ColumnHeader[] = headers;

  public articleName(articleId: string) {
    const article = this.articles[this.articles.findIndex((a) => a._id === articleId)];
    return article ? article.name : '-';
  }

  public categoryName(categoryId: string) {
    const category = this.categories[this.categories.findIndex((a) => a._id === categoryId)];
    return category ? category.name : '-';
  }
}
