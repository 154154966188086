export default [
  {
    text: 'banner.table.name',
    value: 'name',
  },
  {
    text: 'banner.table.type',
    value: 'type',
  },
  {
    text: 'banner.table.linkedCategory',
    value: 'linkedCategory',
    type: 'slot',
  },
  {
    text: 'banner.table.linkedArticle',
    value: 'linkedArticle',
    type: 'slot',
  },
];
