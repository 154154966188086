
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import { Article } from '@/interfaces/models/Article';
import { Category } from '@/interfaces/models/Category';
import { Banner as BannerModel } from '@/interfaces/models/Banner';
import BannerTable from '@/components/banner/BannerTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';

const venue = namespace('venue');
const foodcard = namespace('foodcard');
const banner = namespace('banner');

@Component({
  components: { VEmptyState, BannerTable, VWrapper },
})
export default class Banner extends Vue {
  @venue.State('active') public venue!: Venue;
  @foodcard.State('categories') public categories!: Category[];
  @banner.State('items') public banners!: BannerModel[];
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;

  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.Action('fetchArticleNames') public getArticleNames: any;
  @banner.Action('fetch') public getBanners!: any;
  @banner.Action('delete') public deleteBanner!: any;

  public async created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      await this.fetch();
    }
  }
  public async fetch() {
    this.$startLoading('banners');
    this.$startLoading('articles');
    this.$startLoading('categories');
    this.setCategoryFilter({ venue: this.venue._id });
    await Promise.all([
      this.getCategories(),
      this.getArticleNames({ venue: this.venue._id }),
      this.getBanners(this.venue._id),
    ]);

    this.$stopAllLoading();
  }

  public async deleteCallback(banner: BannerModel) {
    await this.deleteBanner({ venueId: this.venue._id, bannerId: banner._id });
  }

  public async editCallback(banner: BannerModel) {
    await this.$router.push({ name: 'banner.edit', params: { id: banner._id } });
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      await this.fetch();
    }
  }
}
